import FingerprintJS from '@fingerprintjs/fingerprintjs';

const fpPromise = FingerprintJS.load()

export default async function fingerPrint (){
    const fp = await fpPromise
    const result = await fp.get()
  
    // This is the visitor identifier:
    const visitorId = result.visitorId
    return visitorId
}