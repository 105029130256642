import React from "react";
import "./Modal.css";
import { Modal } from "react-bootstrap";
import IModalProps from "../Interface/IModalProps";


export default function CustomModal({
    displayModal,
    onClose,
    modalBody,
    dialogClassName,
    modalBodyClass,
    removeClose,
    size
}: IModalProps) {
    function close(e: any) {
        // e.stopPropagation();
        onClose();
    }
    return (
        <Modal
            show={displayModal}
            // onHide={close}
            dialogClassName={dialogClassName}
            backdropClassName={
                dialogClassName === "profileModal" ||
                    dialogClassName === "notificationModal"
                    ? "disable-backdrop"
                    : ""
            }
            animation={
                dialogClassName === "profileModal" ||
                    dialogClassName === "notificationModal"
                    ? false
                    : true
            }
            size={size}
            
        >
            {!removeClose || dialogClassName === "notificationModal" ?
                <Modal.Header>
                    {dialogClassName === "notificationModal" ? (
                        <span className="modal-notification-header">Notifications</span>
                    ) : null}
                    {
                        !removeClose ? <span className="close pull-right" onClick={close}>
                            &times;
                        </span> : ' '
                    }

                </Modal.Header>
                : " "}
            <Modal.Body className={modalBodyClass}>{modalBody}</Modal.Body>
        </Modal>
    );
}

