import axios, { Method } from 'axios';
import axiosHandler from '../helpers/axioHandler';

export default async function makeRequestPublic(url: string, method: Method, inputPayload?: any) {
    let requestConfig = {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VER}`,
        url: url,
        method: method,
        data: {}
    };

    // console.log(requestConfig);
    if (method !== 'get' && inputPayload) {
        requestConfig.data = inputPayload;
    }

    try {
        let response = await axios.request(requestConfig);
        return response;
    } catch (error: any) {
        axiosHandler(error);
        throw error;
    }

}
