import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { timer } from '../../../helpers/timer/timer';
import { ProgressBar, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardNav from '../../../components/Navbar/DashboardNav';
import { ExamService } from '../../../Services/examServices';
import { CustomizedState } from '../Instructions/Instructions.index';

export default function StartExam() {
    const [second, setSeconds] = useState(10);
    let time = timer(second, setSeconds);
    const [examData, setExamData] = useState({});
    const navigate = useNavigate()
    const state = useLocation().state as CustomizedState
    const examId = state.examId;
    const [questionDataIds, setQuestionDataIds] = useState([]);
    const [s3Url, setS3Url] = useState('');

    const getExam = async () => {
        await ExamService.getExamById(examId)
            .then((res) => {
                if (res.status === 200) {
                    setExamData(res.data.data);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const getAllQuestionIds = async () => {
        await ExamService.getAllQuestionIds(examId)
            .then((res) => {
                if (res.status === 200) {
                    setQuestionDataIds(res.data.data);
                    setS3Url(res.data.s3Url);
                    checkAllDataPresent();
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    const checkAllDataPresent = async () => {
        var cache = await caches.open('cache')
        // cache.put("name" , );
        console.log(caches, "cache ")

        questionDataIds.map((id) => {
            console.log(`/${id}`);
            caches.has(`/${id}`).then((bool) => {
                if (!bool) {
                    console.log("not present ");
                    addToCache(id);
                } else {
                    console.log("present ");
                }
            }).catch((err) => {
                console.error(err, "err in loading cache");
            })
        })
    }
    const addToCache = async (id: string) => {
        await ExamService.getQuestionDataFromS3(s3Url, id)
            .then((res) => {
                addMultipleCacheData(id, res.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const addMultipleCacheData = async (name: string, resData: any) => {
        const data = new Response(JSON.stringify(resData));
        if ('caches' in window) {
            var cache = await caches.open('cache')
            cache.put(name, data);
        }
    };
    useEffect(() => {
        getExam();
        getAllQuestionIds();
    }, [])

    const handelClick = () => {
        navigate('/exam/exam-live', { state: { examData: examData } })
    }
    const progressBarStyle = {
        height: "30px"
    }


    return (
        <>
            <DashboardNav />
            <Container className='d-flex justify-content-md-center align-items-left vh-100 flex-column' >
                <h2>Exam Name </h2>
                <h3>Exam will start in {second}</h3>

                <p>{(second === 0) ? 'Files Downloaded Sucessfully you can start exam now' : 'Downloading Image files  ..'}</p>
                {/* <ProgressBar style={progressBarStyle} now={(10 - second) * 10} label={`${(10 - second) * 10}%`} /> */}

                <Button variant="primary" className={`${second !== 0 ? 'disabled' : ''}  w-25 mt-3`} onClick={handelClick}>Start Exam</Button>
            </Container>
        </>
    )
}