import React from 'react';

interface IMarkedProps {
    count: number
    onClick: any
}

// export function Answered(props: IMarkedProps) {
//     return (
//             <li className="answered" onClick={props.onClick}>
//                 <div className="d-flex justify-content-center">
//                     <span className="clrGreen">{props.count}</span>
//                 </div>
//                 <span className="marker">Answered</span>
//             </li>
//     )
// }

export function Answered(props: IMarkedProps) {
    return (
        <li className="hexagon-text">
            <div className='justify-content-center align-items-center'>
                Answered: {props.count}
            </div>
        </li>
    )
    // return (
    //         <li className="hexagon" onClick={props.onClick}>
    //             <div className="d-flex justify-content-center">
    //                 <span className="clrMauve-hexagon">{props.count}</span>
    //             </div>
    //             <span className="marker">Answered</span>
    //         </li>
    // )
}


export function NotVisited(props: IMarkedProps) {
    // return (
    //         <li className="notvisited" onClick={props.onClick}>
    //             <div className="d-flex justify-content-center">
    //                 <span className="clrMauve">{props.count}</span>
    //             </div>
    //             <span className="marker">Not Visited</span>
    //         </li>
    // )

    return (
        <li className="muted-text">
            <div className='justify-content-center align-items-center'>
                Not Answered: {props.count}
            </div>
        </li>
    )
}


export function Marked(props: IMarkedProps) {
    // return (
    //     <li className="markedanswer" onClick={props.onClick}>
    //         <div className="d-flex justify-content-center">
    //             <span className="clrMauve">{props.count}</span>
    //         </div>
    //         <span className="marker">Marked</span>
    //     </li>
    // )

    return (
        <li className="markedanswer-text">
            <div className='justify-content-center align-items-center'>
                Marked: {props.count}
            </div>
        </li>
    )
}

export function NotAnswered(props: IMarkedProps) {
    // return (
    //         <li className="notanswered" onClick={props.onClick}>
    //             <div className="d-flex justify-content-center">
    //                 <span className="clrRose">{props.count}</span>
    //             </div>
    //             <span className="marker">Not Answered</span>
    //         </li>
    // )

    return (
        <li className="notanswered-text">
            <div className='justify-content-center align-items-center'>
                Not Answered: {props.count}
            </div>
        </li>
    )
}

export function MarkedandAnswered(props: IMarkedProps) {
    // return (
    //         <li className="notanswered" onClick={props.onClick}>
    //             <div className="d-flex justify-content-center">
    //                 <span className="clrRose">{props.count}</span>
    //             </div>
    //             <span className="marker">Not Answered</span>
    //         </li>
    // )

    return (
        <li className="answer-review-text">
            <div className='justify-content-center align-items-center'>
                Marked and Answer: {props.count}
            </div>
        </li>
    )
}

