import React from 'react';
import { Button } from 'react-bootstrap';

export default function ExamSubmission() {
    return (
        <div className="d-flex justify-content-center align-items-center mt-5" style={{minHeight: "100vh;"}}>
            <div className="text-center">
                <div className='mb-3'>
                <svg width="100" height="100" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 11L9 13L13 9.00001M18.6179 4.98434C18.4132 4.99472 18.2072 4.99997 18 4.99997C14.9265 4.99997 12.123 3.84453 9.99993 1.94434C7.87691 3.84446 5.07339 4.99985 2 4.99985C1.79277 4.99985 1.58678 4.9946 1.38213 4.98422C1.1327 5.94783 1 6.95842 1 8.00001C1 13.5915 4.82432 18.2898 10 19.622C15.1757 18.2898 19 13.5915 19 8.00001C19 6.95847 18.8673 5.94791 18.6179 4.98434Z" stroke="#198754" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </div>
                <span className="h1 text-secondary mt-3">
                    Exam Submitted!
                </span>
                <div className="p text-secondary mb-3">
                    Thank You! Your responses have been saved
                </div>
                <div>
                    <Button className="btn-secondary" size="sm" onClick={()=>window.location.href = "/login"}>
                        Go to Login
                    </Button>
                </div>
            </div>
        </div>
    )
}