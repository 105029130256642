export const timer = (second:any, setSeconds:any) => {
    let time = 0;
    const sec = parseInt(second, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;
    let Rhour = "";
    let RMin = "";
    let RSec = "";
    if (hours < 10) {
      Rhour = "0" + hours;
    }
    if (minutes < 10) {
      RMin = "0" + minutes;
    }
    if (seconds < 10) {
      RSec = "0" + seconds;
    }
    if (second > 0) {
      setTimeout(() => setSeconds(second - 1), 1000);
    }
    if (hours == 0) {
      return +RMin + ":" + RSec; // Return in MM:SS format
    } else {
      return Rhour+ ":" +RMin + ":" +RSec; // Return in HH:MM:SS format
    }
  };