export function zoomIn() {
  var Page: any = document.getElementById('Body');
  var zoom = parseInt(Page.style.zoom) + 10 + '%'
  Page.style.zoom = zoom;
  return false;
}

export function zoomOut() {
  var Page: any = document.getElementById('Body');
  var zoom = parseInt(Page.style.zoom) - 10 + '%'
  Page.style.zoom = zoom;
  return false;
}