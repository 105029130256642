import { faGraduationCap, faInfo } from "@fortawesome/free-solid-svg-icons";
import IRouter from "../interfaces/IRouter";
import Exam from "../view/Private/Exam/Exam";

export const liveBaseUrl = "/live";

const liveRoutes:IRouter[] = [
    {
        path: "exam-live",
        navbarShow: false,
        element: Exam,
        name: "Live Exam",
        icon: faGraduationCap
    }
]

export default liveRoutes;