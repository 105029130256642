import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { instructionData } from '../../data/data'
import { ExamService } from '../../Services/examServices'
import LiveExam from '../../Services/liveexam.services'
import CustomModal from './Modal'
import { Document, Page } from 'react-pdf'
import AllPagesPDFViewer from '../../view/Private/Instructions/pdfview/AllPagesPdfViewer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'


interface IInfo {
    show: any
    setShow: any
}

const InfoMadal = ({ show, setShow }: IInfo) => {

    const [instructions, setInstructions] = useState<string[]>()

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [numPages, setNumPages] = useState<any>(null);

    const [instructionData, setInstructionData] = useState<any>();


    const getExamInstructions = async () => {
        await LiveExam.getInstructions().then((res) => {
            if (res.status === 200) {
                setInstructions(res.data.data.instructions);
            }
        }).catch(e => {
            console.error(e)
        })
    }

    const onDocumentLoadSuccess = () => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    useEffect(() => {
        getExamInstructions()
    }, [])


    return (
        <>
            <CustomModal
                displayModal={show}
                onClose={() => setShow(false)}
                modalBodyClass="px-2"
                removeClose={true}
                size="xl"
                modalBody={
                    <>
                    <div className='d-flex justify-content-end align-items-center'>
                        <FontAwesomeIcon icon={faTimesCircle} onClick={()=>setShow(false)}/>
                    </div>
                        <Container className="bg-white exam-content rounded shadow my-2 p-3">
                            <div className="text-left">
                                <h1 ><b>Important Instructions</b></h1>
                            </div>
                            <Row>
                                <Col>
                                    {
                                        instructions && <>
                                            <AllPagesPDFViewer pdf={instructions} />
                                        </>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Button variant="secondary" onClick={() => setShow(false)} className="float-right me-2">Close</Button>
                                </Col>
                            </Row>
                        </Container>
                    </>
                }
            />

        </>
    )
}

export default InfoMadal