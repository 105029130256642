import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { io } from 'socket.io-client'
import NavBar from '../../../components/NavBar'
import { errorResponseHelper } from '../../../helpers/request.helper'
import LiveExam from '../../../Services/liveexam.services'
import { ExamData, ExamDetails, MappingData, ResponsesData, SelectedQuestion } from './context'
import './Exam.css'
import ExamHome from './ExamHome/ExamHome'

interface IState {
    examData: any
}

interface ISelectedQuestion {
    section: string,
    question: string,
    language: string
}

export default function Exam() {
    const state = useLocation().state as IState


    // CONTEXTS
    const [examDetails, setExamDetails] = useState<any>();
    const [examData, setExamData] = useState<any>();
    const [mappingData, setMappingData] = useState<any>();
    const [responsesData, setResponsesData] = useState<any>();
    const [selectedQuestionId, setSelectedQuestionId] = useState<ISelectedQuestion>();

    const getExamDetails = async () => {
        await LiveExam.getExamDetails().then(res => {
            if (res.status === 200) {
                setExamDetails(res.data.data)
            }
        }).catch(e => {
            errorResponseHelper(e)
        })
    }


    const getExamData = async () => {
        await LiveExam.getExamData().then(res => {
            if (res.status === 200) {
                setExamData(res.data.data)
            }
        }).catch(e => {
            errorResponseHelper(e)
        })
    }

    const getResponses = async () => {
        await LiveExam.getResponses().then(res => {
            if (res.status === 200) {
                setResponsesData(res.data.data)
                setSelectedQuestionId({
                    question: res.data.data.questionArr[0].questions[0],
                    section: res.data.data.questionArr[0].section,
                    language: 'english'
                })
            }
        }).catch(e => {
            errorResponseHelper(e)
        })
    }

    useEffect(() => {
        getExamDetails()
        getExamData()
        getResponses();
        setMappingData(state)
        // connectSocket()

    }, [])

    const connectSocket = async () => {

        const socket = io(`${process.env.REACT_APP_API_URL}`, {
            auth: {
              token: sessionStorage.getItem("liveToken")
            },
            rejectUnauthorized: false
          });
          socket.on("connect_error", (err) => {
            console.log(err.message); // prints the message associated with the error
          });
          console.log(socket)
    }

    useEffect(()=>{
        connectSocket();
    },[])

    return (
        <>
            <MappingData.Provider value={mappingData}>
                <ExamDetails.Provider value={examDetails}>
                    <ResponsesData.Provider value={{ responsesData, setResponsesData }}>
                        <SelectedQuestion.Provider value={{ selectedQuestionId, setSelectedQuestionId }}>
                            <ExamData.Provider value={examData}>
                                <NavBar />
                                <ExamHome />
                            </ExamData.Provider>
                        </SelectedQuestion.Provider>
                    </ResponsesData.Provider>
                </ExamDetails.Provider>
            </MappingData.Provider>
        </>
    )
}